import { formatDateTime } from "../util/formatDataTime"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "./MessageReporter"
import { ApiClient } from "../ApiClient"
import { Button, Table } from "react-bootstrap"

const pageSize = 4

export const ErrorTimeline = ({ systemId, focusErrorTab }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [errorGroups, setErrorGroups] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)

    const [page, setPage] = useState(0)

    useEffect(() => {
        setLoading(true)
        const abortController = new AbortController()

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(
                    `/v2/consumer/systems/${systemId}/errors?page=${page}&size=${pageSize}`,
                    {
                        Authorization: "Bearer " + token,
                    },
                    abortController.signal
                )
            )
            .then(({ data }) => {
                setErrorGroups((current) => [
                    ...current,
                    ...data.error_groups.map(calcHightestLevel),
                ])
                setTotal(data.total_count)

                if (data.error_groups[0]?.errors.length > 0) {
                    focusErrorTab()
                }
            })
            .catch((err) => {
                err !== "abort" &&
                    addMessage(
                        "Failed to get errors",
                        "Something went wrong while getting errors, " +
                            "see the console for more details.",
                        MessageType.error,
                        err
                    )
            })
            .finally(() => setLoading(false))

        return () => abortController.abort("abort")
    }, [page, systemId, getAccessTokenSilently, setLoading, addMessage, focusErrorTab])

    return (
        <div className="d-flex justify-content-sm-center overflow-x-auto py-4">
            {errorGroups.length === 0 ? (
                loading ? (
                    "Loading..."
                ) : (
                    "No errors found"
                )
            ) : (
                <div className="error-history">
                    {errorGroups.map((group, index) => (
                        <ErrorGroup key={group.start} errorGroup={group} latest={index === 0} />
                    ))}
                    {total > errorGroups.length && (
                        <div className="error-group">
                            <div></div>
                            <div className="error-line-button"></div>
                            <div className="error-group-button">
                                <Button
                                    variant="primary"
                                    className="error-load-more"
                                    onClick={() => setPage(page + 1)}
                                    disabled={loading}
                                >
                                    Load more
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

const ErrorGroup = ({ errorGroup, latest }) => (
    <div className={"error-group error-level-" + errorGroup.highestLevel}>
        <div className="error-times">
            {latest && <div className="error-times-end">now</div>}
            <div className="error-times-start">{formatDateTime(errorGroup.start)}</div>
        </div>
        <div className="error-line"></div>
        <div className="error-table">
            {errorGroup.errors.length > 0 && (
                <Table>
                    <thead>
                        <tr>
                            <th>Serial number</th>
                            <th>Level</th>
                            <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorGroup.errors.map((error) => (
                            <tr key={error.serial_number + error.level + error.code}>
                                <td>{error.serial_number}</td>
                                <td>{error.level}</td>
                                <td>{error.code}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    </div>
)

const calcHightestLevel = (errorGroup) => ({
    ...errorGroup,
    highestLevel:
        errorGroup.errors
            .map((error) => error.level)
            .sort((a, b) => levelToInt(a) - levelToInt(b))
            .shift() ?? "none",
})

const levelToInt = (level) => {
    switch (level) {
        case "service_required":
            return 1
        case "error":
            return 2
        case "warning":
            return 3
        default:
            throw new Error("unknown error level: " + level)
    }
}
