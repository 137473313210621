import { useAuth0 } from "@auth0/auth0-react"
import React, { useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { useNavigate } from "react-router-dom"
import { FirmwareUriInput } from "../../components/Firmware/FirmwareUriInput"
import { FirmwareVersionInput } from "../../components/Firmware/FirmwareVersionInput"

export const AddFirmware = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const navigate = useNavigate()

    const [version, setVersion] = useState("")
    const [firmware, setFirmware] = useState({
        device: "",
        uri: "",
    })
    const formValid = version !== "" && firmware.device !== "" && firmware.uri !== ""

    const create = () => {
        const body = {
            type: "VERSION",
            version: version,
            device_type: firmware.device,
            uri: firmware.uri,
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post("/v2/management/firmwares", body, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ headers }) => navigate("/firmware/" + headers.location))
            .catch((e) => {
                addMessage(
                    "Failed to add firmware",
                    "Something went wrong while updating the firmware, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }

    return (
        <Row className="content-section">
            <Col>
                <h2>Add firmware version</h2>
                <Form.Group className="mb-3">
                    <Form.Label>Version</Form.Label>
                    <FirmwareVersionInput setVersion={setVersion} />
                </Form.Group>

                <FirmwareUriInput setFirmware={setFirmware} />

                <Button disabled={!formValid} onClick={() => create()}>
                    Create
                </Button>
            </Col>
        </Row>
    )
}
