import { flattenFirmwares } from "../../components/Firmware/FirmwareChain"
import { formatDateTime } from "../../util/formatDataTime"
import { ConnectedIcon } from "../ConnectedIcon"
import { InfoList } from "../InfoList"

export const DeviceInfo = ({ device }) => {
    const setFirmware = flattenFirmwares(device.set_firmware).pop()?.version

    return (
        <InfoList>
            <InfoList.Item name={"Product"} value={device.display_name} />
            <InfoList.Item name={"Product number"} value={device.pn} />
            <InfoList.Item name={"Serial number"} value={device.sn} />
            <InfoList.Item
                name={"Firmware"}
                value={
                    device.current_version !== setFirmware && setFirmware !== undefined ? (
                        <>
                            {device.current_version}
                            <i className="fa-solid fa-arrow-right-long mx-2"></i>
                            {setFirmware}
                        </>
                    ) : (
                        device.current_version
                    )
                }
            />
            <InfoList.Item
                name={"Active since"}
                value={formatDateTime(device.created_at, "yyyy-MM-dd")}
            />
            <InfoList.Item name={"Up since"} value={formatDateTime(device.up_since)} />
            <InfoList.Item
                name={"Connected"}
                value={
                    <>
                        <ConnectedIcon connected={device.connected} className="me-3" />
                        {device.connected ||
                            `Disconnected since ${formatDateTime(device.last_connection)}`}
                    </>
                }
            />
        </InfoList>
    )
}
