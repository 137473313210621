import { useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { Form, Button, ButtonGroup } from "react-bootstrap"
import { FirmwareSelector } from "../../components/Firmware/FirmwareSelector"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { InfoList } from "../InfoList"
import { formatDateTime } from "../../util/formatDataTime"

export const DeviceInfoAdmin = ({ device, reloadDevice }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [editing, setEditing] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [remarks, setRemarks] = useState("...")
    const [attachedFirmware, setAttachedFirmware] = useState({
        label: "...",
        value: {},
    })

    useEffect(() => {
        setEditing(false)
        setRemarks(device.remarks)
        setAttachedFirmware({
            label: device.set_firmware?.name || device.set_firmware?.version,
            value: device.set_firmware,
        })
    }, [device])

    const update = () => {
        setSaveDisabled(true)
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.put(
                    "/v2/management/devices/" + device.device_id,
                    {
                        remarks: remarks,
                        firmware_id: attachedFirmware.value.firmware_id,
                    },
                    {
                        Authorization: "Bearer " + token,
                    }
                )
            )
            .then(() => {
                addMessage(
                    "Device updated",
                    "Successfully updated device",
                    MessageType.success,
                    null
                )
                reloadDevice()
            })
            .catch((e) => {
                addMessage(
                    "Failed to update device",
                    "Something went wrong while updating the device, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
            .finally(setSaveDisabled(false))
    }

    return (
        <>
            <InfoList>
                <InfoList.Item name={"Device ID"} value={device.device_id} />
                <InfoList.Item
                    name={"Disconnect reason"}
                    value={device.disconnect_reason?.toLowerCase()}
                />
                <Remarks value={remarks} setValue={setRemarks} editing={editing} />
                <InfoList.Item name={"Created at"} value={formatDateTime(device.created_at)} />
                <InfoList.Item name={"Updated at"} value={formatDateTime(device.updated_at)} />

                <SetFirmware
                    value={attachedFirmware}
                    setValue={setAttachedFirmware}
                    editing={editing}
                    deviceType={device.type}
                />
                {!device.update_status ? (
                    <InfoList.Item name="Update status" value="NONE" />
                ) : (
                    <>
                        <InfoList.Item name={"Update status"} value={device.update_status.state} />
                        {!["OK", "DONE", "NONE"].includes(device.update_status.last_error_code) && (
                            <>
                                <InfoList.Item
                                    name="Last update error"
                                    value={device.update_status.last_error_code}
                                />
                                <InfoList.Item
                                    name="Update attempts remaining"
                                    value={device.update_status.attempts_remaining}
                                />
                                <InfoList.Item
                                    name="Next update attempt at"
                                    value={formatDateTime(device.update_status.next_attempt_time)}
                                />
                            </>
                        )}
                    </>
                )}
            </InfoList>
            <div className="d-flex justify-content-end">
                {editing ? (
                    <ButtonGroup className="ms-auto">
                        <Button
                            variant="light"
                            onClick={() => {
                                setEditing(false)
                                setRemarks(device.remarks)
                                setAttachedFirmware({
                                    label: device.set_firmware.name || device.set_firmware.version,
                                    value: device.set_firmware,
                                })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="success" disabled={saveDisabled} onClick={update}>
                            Save
                        </Button>
                    </ButtonGroup>
                ) : (
                    <Button
                        disabled={
                            device.remarks === undefined ||
                            device.set_firmware?.firmware_id === undefined
                        }
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                )}
            </div>
        </>
    )
}

const Remarks = ({ value, setValue, editing }) => (
    <tr>
        <td>Remarks</td>
        <td>
            {value === undefined ? (
                "..."
            ) : !editing ? (
                value
            ) : (
                <Form.Control
                    disabled={!editing}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            )}
        </td>
    </tr>
)

const SetFirmware = ({ value, setValue, editing, deviceType }) => (
    <tr>
        <td>Selected firmware</td>
        <td>
            {value === undefined ? (
                "..."
            ) : !editing ? (
                value.label
            ) : (
                <FirmwareSelector value={value} setValue={setValue} onlyDeviceOfType={deviceType} />
            )}
        </td>
    </tr>
)
