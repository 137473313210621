import { Button, Form } from "react-bootstrap"
import { useHistory, periodMap } from "../HistoryContext"
import { isValid, format, sub, add } from "date-fns"

export const DeviceHistoryControls = () => {
    const { setIsZoomed, setUiDate, uiDate, setPeriod, period, exportGraph } = useHistory()

    const periodData = periodMap[period]

    const prev = (current, _period) => sub(current, _period.duration)
    const next = (current, _period) => add(current, _period.duration)

    return (
        <div className="row">
            <div
                className="
            col-xl-6 offset-xl-3
            col-md-8 offset-md-2
            col-12
            d-md-flex"
                style={{ gap: "10px" }}
            >
                <Button
                    variant="text"
                    onClick={() => {
                        setIsZoomed(false)
                        setUiDate(prev(uiDate, periodData))
                    }}
                >
                    <i className="fa-solid fa-circle-arrow-left fa-xl"></i>
                </Button>
                <Form.Select
                    value={period}
                    onChange={(e) => {
                        setIsZoomed(false)
                        setPeriod(e.target.value)
                    }}
                >
                    {Object.keys(periodMap).map((_period) => (
                        <option key={_period}>{_period}</option>
                    ))}
                </Form.Select>
                <Form.Control
                    type="date"
                    onChange={(e) => {
                        setUiDate(new Date(e.target.value))
                    }}
                    value={isValid(uiDate) ? format(uiDate, "yyyy-MM-dd") : ""}
                    isInvalid={!isValid(uiDate)}
                />
                <Button
                    variant="text"
                    onClick={() => {
                        setIsZoomed(false)
                        setUiDate(next(uiDate, periodData))
                    }}
                >
                    <i className="fa-solid fa-circle-arrow-right fa-xl"></i>
                </Button>
            </div>
            <div className="col-xl-3 col-md-2 col-12 text-end">
                <Button variant="text" onClick={() => setIsZoomed(false)}>
                    <i className="fa-solid fa-magnifying-glass-chart fa-xl"></i>
                </Button>
                <Button variant="text" onClick={exportGraph}>
                    <i className="fa-solid fa-file-csv fa-xl"></i>
                </Button>
            </div>
        </div>
    )
}
