import { Badge, ListGroup, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

export const FirmwareChain = ({ firmware, doneLoading }) =>
    !doneLoading ? (
        <Spinner variant="primary" />
    ) : firmware ? (
        <ListGroup variant="flush">
            {flattenFirmwares(firmware).map((fw) => (
                <Item
                    key={fw.firmware_id}
                    fw={fw}
                    isGroup={!!fw.child}
                    isAttached={firmware.firmware_id === fw.firmware_id}
                />
            ))}
        </ListGroup>
    ) : (
        "No linked firmware version or device groups."
    )

const Item = ({ fw, isGroup, isAttached }) => (
    <ListGroup.Item
        action
        as={Link}
        to={(isGroup ? "/devicegroup/" : "/firmware/") + fw.firmware_id}
        className="d-flex justify-content-between align-items-start text-muted"
    >
        <div className="fw-bold" style={{ minWidth: "80px" }}>
            {isGroup ? "Group" : "Version"}
        </div>
        <div className="text-break me-auto">
            {isGroup ? (
                fw.name
            ) : (
                <>
                    {fw.version} <br /> {fw.uri}
                </>
            )}
        </div>
        {isAttached && <Badge bg="primary">Directly attached</Badge>}
    </ListGroup.Item>
)

export const flattenFirmwares = (firmware) => {
    if (!firmware) {
        return []
    }

    const firmwares = []
    while (firmware.child) {
        firmwares.push(firmware)
        firmware = firmware.child
    }
    firmwares.push(firmware)

    return firmwares
}
