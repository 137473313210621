import { useCallback, useEffect, useMemo, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { useAuth0 } from "@auth0/auth0-react"
import { Link, useParams } from "react-router-dom"
import { useMessageReport, MessageType } from "../../components/MessageReporter"
import { Col, Collapse, Fade, Row, Tab, Tabs } from "react-bootstrap"
import { InfoList } from "../../components/InfoList"
import { DeviceInfo } from "../../components/Device/DeviceInfo"
import { ConnectedIcon } from "../../components/ConnectedIcon"
import { DeviceLatestValues } from "../../components/Device/DeviceLatestValues"
import { CloudConnectedIcon } from "../../components/CloudConnectedIcon"
import classNames from "classnames"
import { ErrorTimeline } from "../../components/ErrorTimeline"
import { SystemGraphs } from "../../components/Device/SystemGraphs"

const flattenDevices = (dev) => [dev, ...dev.attached_devices.flatMap((d2) => flattenDevices(d2))]

export const SystemDetail = () => {
    const [activeTab, setActiveTab] = useState("history")
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const { id } = useParams()
    const [system, setSystem] = useState()
    const [open, setOpen] = useState(false)
    const [activeDevice, setActiveDevice] = useState({})
    const [consentGranted, setConsentGranted] = useState(false)
    const [fade, setFade] = useState(false)

    const focusErrorTab = useCallback(() => setActiveTab("errors"), [setActiveTab])

    const deviceRows = useMemo(
        () => system?.devices.map((dev) => flattenDevices(dev)) || [],
        [system]
    )

    const setOpenedDevice = (dev) => {
        setFade(false)
        if (dev !== activeDevice && open) {
            setTimeout(() => {
                setActiveDevice(dev)
                setFade(true)
            }, 300)
        } else {
            setActiveDevice(dev)
            setOpen(!open)
            setFade(true)
        }
    }

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/consumer/systems/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setSystem(data.system)
                setConsentGranted(data.consent.accepted)
            })
            .catch((e) => {
                addMessage(
                    "Failed to get system",
                    "Something went wrong while getting the system, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, addMessage, id])

    return (
        <>
            <Row className="content-section">
                <Col>
                    <h2>Information</h2>
                    <InfoList>
                        <InfoList.Item
                            name="System ID"
                            value={
                                <abbr
                                    style={{
                                        cursor: "inherit",
                                        textDecorationLine: "none",
                                    }}
                                    title={id}
                                >
                                    {id.slice(30, 36).toUpperCase()}
                                </abbr>
                            }
                        />
                        <InfoList.Item
                            name="Consent"
                            value={
                                <i
                                    className={
                                        "fa-solid " + (consentGranted ? "fa-check" : "fa-xmark")
                                    }
                                />
                            }
                        />
                    </InfoList>
                    <div className="systems-overview">
                        {system &&
                            deviceRows
                                .sort((a, b) => b.length - a.length)
                                .map((systemRow, systemIndex) => (
                                    <div key={systemIndex} className="system-row">
                                        {systemRow.map((dev, deviceIndex) => (
                                            <div
                                                key={dev.sn}
                                                className="system mb-3"
                                                onClick={() => {
                                                    setOpenedDevice(dev)
                                                }}
                                                style={{ gridRowStart: 1 + systemIndex }}
                                            >
                                                <DeviceImg deviceType={dev.type} />
                                                <div className="device-information">
                                                    {deviceIndex === 0 ? (
                                                        <CloudConnectedIcon
                                                            connected={dev.connected}
                                                        />
                                                    ) : (
                                                        <ConnectedIcon connected={dev.connected} />
                                                    )}
                                                    <span
                                                        className={classNames("ms-2", {
                                                            active:
                                                                activeDevice &&
                                                                open &&
                                                                activeDevice.device_id ===
                                                                    dev.device_id,
                                                        })}
                                                    >
                                                        {dev.display_name}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                    </div>
                    <Collapse in={open}>
                        <div className="device-info-container">
                            <Link
                                className="device-wrapper"
                                to={`/device/${activeDevice.device_id}`}
                            >
                                <Fade in={fade}>
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <h3>Information</h3>
                                                <DeviceInfo device={activeDevice} />
                                            </Col>
                                            {activeDevice.connected && consentGranted && (
                                                <Col md={6}>
                                                    <h3>Status</h3>
                                                    <DeviceLatestValues
                                                        id={activeDevice.device_id}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </Fade>
                            </Link>
                        </div>
                    </Collapse>
                </Col>
            </Row>
            <Row className="content-section">
                <Col>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(t) => setActiveTab(t)}
                        className="mb-3"
                        justify
                        mountOnEnter={false}
                        unmountOnExit={false}
                    >
                        <Tab eventKey="history" title="History">
                            <SystemGraphs systemId={id} />
                        </Tab>
                        <Tab eventKey="errors" title="Errors">
                            <ErrorTimeline systemId={id} focusErrorTab={focusErrorTab} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

const DeviceImg = ({ deviceType }) => (
    <div className="device-visualization">
        <div
            className="device-image"
            style={{
                backgroundImage: `url(/devices/${deviceType}.svg)`,
            }}
        ></div>
        <div className="device-image-connecting-line"></div>
    </div>
)
