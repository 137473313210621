import React from "react"

export class GenericErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fallback: props.fallback,
            error: null,
        }
    }

    static getDerivedStateFromError(err) {
        return { error: err }
    }

    render() {
        return this.state.error == null ? (
            this.props.children
        ) : (
            <this.state.fallback error={this.state.error} />
        )
    }
}
