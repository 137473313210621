import React from "react"
import { SortableTh } from "../../components/SortableColumns"
import { Link, useNavigate } from "react-router-dom"
import { DetailLink } from "../../components/Table/DetailLink"
import { GenericOverview } from "../../components/Table/GenericOverview"
import { Col, Row } from "react-bootstrap"

export const FirmwareOverview = () => {
    const endpoint = "/v2/management/firmwares"
    const navigate = useNavigate()

    const jsonDataField = (setData, data) => setData(data.firmwares)

    const filter = {
        initial: [[["type", "VERSION"]]],
        onSearch: (filters, searchText) => {
            filters.push(["AND"])
            filters.push(["version", "like", searchText])
            filters.push(["OR"])
            filters.push(["device_type", "like", searchText])
            filters.push(["OR"])
            filters.push(["uri", "like", searchText])
            return filters
        },
    }

    const error = {
        title: "Failed to get firmware versions",
        body:
            "Something went wrong while getting the firmware versions, " +
            "see the console for more details.",
    }

    const emptyMsg = "No firmware versions found"

    const table = {
        header: [
            <SortableTh column="version">Version</SortableTh>,
            <SortableTh column="device_type">Device type</SortableTh>,
            <SortableTh column="uri">Uri</SortableTh>,
            <th>Actions</th>,
        ],
        body: (value) => (
            <tr
                key={value.firmware_id}
                onClick={() => {
                    navigate(`/firmware/${value.firmware_id}`)
                }}
            >
                <td>{value.version}</td>
                <td>{value.device_type}</td>
                <td>{value.uri}</td>
                <td>
                    <DetailLink to={`/firmware/${value.firmware_id}`} />
                </td>
            </tr>
        ),
    }

    return (
        <Row className="content-section">
            <Col>
                <h2 className="float-start">Firmware versions</h2>
                <Link className="btn btn-success float-end mb-3" to="/firmware/add">
                    Add firmware version
                </Link>
                <GenericOverview
                    apiEndpoint={endpoint}
                    dataField={jsonDataField}
                    filter={filter}
                    error={error}
                    table={table}
                    emptyMsg={emptyMsg}
                />
            </Col>
        </Row>
    )
}
